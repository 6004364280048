import React from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import AddMembership from 'src/components/saas-memberships/membership-form/membership-form';
import SEO from 'src/components/seo';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Add Membership" />
      <PrivateRoute>
        <AddMembership />
      </PrivateRoute>
    </Layout>
  );
};

export default IndexPage;
